var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { backTo: "/", title: "Property Setup" } }),
      _c(
        "v-content",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "pt-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mb-5", attrs: { xs12: "", sm12: "" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "mb-5",
                              attrs: {
                                xs12: "",
                                sm12: "",
                                "text-xs-center": "",
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "machine",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    x: "0px",
                                    y: "0px",
                                    viewBox: "0 0 645 526",
                                  },
                                },
                                [
                                  _c("circle", {
                                    staticClass: "circle first-circle",
                                    attrs: {
                                      fill: "#DDF0FF",
                                      cx: "200",
                                      cy: "200",
                                      r: "200",
                                    },
                                  }),
                                  _c("defs"),
                                  _c("g", [
                                    _c("path", {
                                      staticClass: "large-shadow",
                                      attrs: {
                                        x: "-173,694",
                                        y: "-173,694",
                                        d: "M645 194v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L602 68l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L482 10h-21l-4 29c-10 1-19 3-28 6l-14-25 -19 8 7 28c-8 5-16 10-24 16l-23-17L341 68l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L645 194zM471 294c-61 0-110-49-110-110S411 74 471 74s110 49 110 110S532 294 471 294z",
                                      },
                                    }),
                                  ]),
                                  _c("g", [
                                    _c("path", {
                                      staticClass: "medium-shadow",
                                      attrs: {
                                        x: "-136,996",
                                        y: "-136,996",
                                        d: "M402 400v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L352 323c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L402 400zM265 463c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C338 430 305 463 265 463z",
                                      },
                                    }),
                                  ]),
                                  _c("g", [
                                    _c("path", {
                                      staticClass: "small-shadow",
                                      attrs: {
                                        x: "-100,136",
                                        y: "-100,136",
                                        d: "M210 246v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H100l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L10 225v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L210 246zM110 272c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S131 272 110 272z",
                                      },
                                    }),
                                  ]),
                                  _c("g", [
                                    _c("path", {
                                      staticClass: "small",
                                      attrs: {
                                        x: "-100,136",
                                        y: "-100,136",
                                        d: "M200 236v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H90l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L0 215v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L200 236zM100 262c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S121 262 100 262z",
                                      },
                                    }),
                                  ]),
                                  _c("g", [
                                    _c("path", {
                                      staticClass: "large",
                                      attrs: {
                                        x: "-173,694",
                                        y: "-173,694",
                                        d: "M635 184v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L592 58l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L472 0h-21l-4 29c-10 1-19 3-28 6L405 9l-19 8 7 28c-8 5-16 10-24 16l-23-17L331 58l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L635 184zM461 284c-61 0-110-49-110-110S401 64 461 64s110 49 110 110S522 284 461 284z",
                                      },
                                    }),
                                  ]),
                                  _c("g", [
                                    _c("path", {
                                      staticClass: "medium",
                                      attrs: {
                                        x: "-136,996",
                                        y: "-136,996",
                                        d: "M392 390v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L342 313c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L392 390zM255 453c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C328 420 295 453 255 453z",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "headline font-weight-medium mb-3 black--text",
                                },
                                [_vm._v("We are processing the onboarding")]
                              ),
                              _c("p", [
                                _vm._v(
                                  "We are processing the onboarding of the new community/association. Please allow us a moment to review your file, if we are in need of any additional documentation, we will be contacting you through email or phone call."
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }